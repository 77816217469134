import type { CreamColor } from "./CreamColor.js";
import type { ProductFigurine } from "./ProductFigurine.js";
import type { ProductFilling } from "./ProductFilling.js";
import type { ProductInscription } from "./ProductInscription.js";
import type { ProductLabel } from "./ProductLabel.js";
import type { ProductMacaroons } from "./ProductMacaroons.js";
import type { ProductNumber } from "./ProductNumber.js";
import type { ProductPhoto } from "./ProductPhoto.js";

/*
 * When adding a new option, make sure:
 * - Admin:
 *	- Configurator has it
 *	- Order detail shows it
 *	- Product cards show it (with filter, if there is one for this option, and without a filter in focus view)
 *	- Product modal in orders branch module shows it
 *	- Komando Ticket shows it
 *	- Correctly shows up in audit records, if it can be updated (statuses for example)
 * - Web: (if not restricted)
 *	- Configurator has it
 *	- Cart shows it
 *	- Order detail shows it
 * - Emails shows it
 * - If the option has some sort of status, make sure it gets properly accounted for in the getOrderManufacturedPercentage function
 */
export enum ProductOption {
	CreamColor = "CreamColor",
	ExtraFruit = "ExtraFruit",
	Figurines = "Figurines",
	Filling = "Filling",
	GlutenFreeVariant = "GlutenFreeVariant",
	Inscription = "Inscription",
	Labels = "Labels",
	LactoseFreeVariant = "LactoseFreeVariant",
	LyingPhoto = "LyingPhoto",
	Macaroons = "Macaroons",
	Meringues = "Meringues",
	Numbers = "Numbers",
	StandingPhotos = "StandingPhotos",
}

export interface ProductOptionValueTypes {
	[ProductOption.CreamColor]?: CreamColor;
	[ProductOption.ExtraFruit]: boolean;
	[ProductOption.Figurines]: ProductFigurine[];
	[ProductOption.Filling]?: ProductFilling;
	[ProductOption.GlutenFreeVariant]: boolean;
	[ProductOption.Inscription]?: ProductInscription;
	[ProductOption.Labels]: ProductLabel[];
	[ProductOption.LactoseFreeVariant]: boolean;
	[ProductOption.LyingPhoto]?: ProductPhoto;
	[ProductOption.Macaroons]?: ProductMacaroons;
	[ProductOption.Meringues]: boolean;
	[ProductOption.Numbers]: ProductNumber[];
	[ProductOption.StandingPhotos]: ProductPhoto[];
}

export const productOptionKeyMap = {
	[ProductOption.CreamColor]: "creamColor" as const,
	[ProductOption.ExtraFruit]: "extraFruit" as const,
	[ProductOption.Figurines]: "figurines" as const,
	[ProductOption.Filling]: "filling" as const,
	[ProductOption.GlutenFreeVariant]: "glutenFree" as const,
	[ProductOption.Inscription]: "inscription" as const,
	[ProductOption.Labels]: "labels" as const,
	[ProductOption.LactoseFreeVariant]: "lactoseFree" as const,
	[ProductOption.LyingPhoto]: "lyingPhoto" as const,
	[ProductOption.Macaroons]: "macaroons" as const,
	[ProductOption.Meringues]: "meringues" as const,
	[ProductOption.Numbers]: "numbers" as const,
	[ProductOption.StandingPhotos]: "standingPhotos" as const,
} satisfies Record<ProductOption, string>;
