import { PaymentType } from "../../../../core/schema/PaymentType.js";
import { PriceLevel } from "../../../../core/schema/PriceLevel.js";
import { ProductOption, productOptionKeyMap } from "../../../../core/schema/ProductOption.js";
import { deliveryTypeToBranchMap } from "../../../../core/schema/deliveryTypeToBranchMap.js";
import { restrictedProductOptionsForExpress } from "../../../../core/schema/restrictedProductOptionsForExpress.js";
import { getAppliedVoucherValue } from "../../../../core/schema/utils/getAppliedVoucherValue.js";
import { getCantPayOnDeliveryReasons } from "../../../../core/schema/utils/getCantPayOnDeliveryReasons.js";
import { getPriceOfOrder } from "../../../../core/schema/utils/getPriceOfOrder.js";
import { isProductOptionUsed } from "../../../../core/schema/utils/isProductOptionUsed.js";
import type { Cart } from "./Cart.js";
import { CartStep, cartStepOrderMap } from "./CartStep.js";

export function cartWithAppliedProperties<T extends Cart>(cart: T): T {
	const currentCartStep = cartStepOrderMap[cart.step];
	const branch = cart.order.deliveryType !== undefined ? deliveryTypeToBranchMap[cart.order.deliveryType] : undefined;

	const cantPayOnDeliveryReasons = getCantPayOnDeliveryReasons(cart.order, PriceLevel.Basic, branch ?? null);
	if (cantPayOnDeliveryReasons.length > 0) {
		if (cart.order.payment?.type === PaymentType.OnDelivery) {
			cart.order.payment.type = undefined;

			if (currentCartStep > cartStepOrderMap[CartStep.PaymentDelivery]) {
				cart.step = CartStep.PaymentDelivery;
			}
		}
	}

	const price = getPriceOfOrder(cart.order, PriceLevel.Basic, branch ?? null);
	const isFree = price.totalPrice.withTax - getAppliedVoucherValue(cart.order.appliedVoucher, price) <= 0;
	if (cart.order.payment && cart.order.payment.type !== PaymentType.OnDelivery && isFree) {
		cart.order.payment.type = PaymentType.OnDelivery;
	}

	const isExpress =
		cart.expressOrderProducts.length > 0 &&
		cart.order.products.every(
			({ id, options }) =>
				cart.expressOrderProducts.includes(id) &&
				(!options ||
					Object.values(ProductOption).every((option) => {
						const isUsed = isProductOptionUsed(option, options[productOptionKeyMap[option]]);
						const isRestrictedForExpress = restrictedProductOptionsForExpress.includes(option);
						return !isUsed || !isRestrictedForExpress;
					})),
		);

	const isSoldOutExpress = isExpress && cart.productsInOrder.some(({ soldOut }) => soldOut);
	const canAddSoldOutExpressProduct = isExpress || cart.order.products.length === 0;

	return {
		...cart,
		order: {
			...cart.order,
			isExpress,
		},
		properties: {
			cantPayOnDeliveryReasons,
			canAddSoldOutExpressProduct,
			isFree,
			isSoldOutExpress,
		},
	};
}
